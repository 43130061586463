const rlsEnvDetails = [
  {
    displayName: 'Dev US',
    externalDisplayName: 'Dev NA',
    envName: 'Dev',
    region: 'US',
    pageLink: 'dev-us',
    hasDrSupport: false,
    resourceCode: 'rls03',
    domain: 'congacloud.io',
    githubBranchName: 'dev-rls03',
    cloudopsManagedEnv: false,
    urls: {
      cm: 'https://rls-dev-cm.congacloud.io',
      login: 'https://login-rlsdev.congacloud.io',
      base: 'https://rls-dev.congacloud.io',
      coreapps: 'https://coreapps-rlsdev.congacloud.io',
      xauthor: 'https://xauthor-dev.congacloud.io',
      cci: 'https://cci-rlsdev.congacloud.io'
    },
    cloudflare: {
      zoneId: '7f069124a98de8f87c7bac8f85cf7c65',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://perf-telemetry.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Development',
      envName: 'DEVELOPMENT',
      url: 'https://rancher-engg.congacloud.io',
      fetchFromRancher: true
    },
    teams: {
      releaseNotificationWebhook: ''
    },
    doppler: {
      projectName: 'dev'
    },
    redis: {
      primary: {
        code: 'data'
      },
      secondary: null
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    aws: {
      primary: {
        region: 'us-west-2',
        regionCode: 'usw2',
        assumeRole: 'arn:aws:iam::022784664444:role/CrossAccountRole_OpsPortal'
      },
      secondary: null,
      accountId: '022784664444'
    },
    tidb: [
      {
        siteName: '',
        dashboardUrl:
          'http://a1b96f62847a14950b0edfdedb28ee9f-9a79fe1453bae3f3.elb.us-west-2.amazonaws.com:12333/dashboard'
      }
    ],
    primaryCountryCode: 'US',
    secondCountryCode: '',
    launchDarkly: {
      opsPortalClientId: '66b438350234810f3f1fface'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'QA US',
    externalDisplayName: 'QA NA',
    envName: 'QA',
    region: 'US',
    pageLink: 'qa-us',
    hasDrSupport: false,
    resourceCode: 'rls04',
    domain: 'congacloud.io',
    githubBranchName: 'qa-rls04',
    cloudopsManagedEnv: false,
    urls: {
      cm: 'https://rls-qa-cm.congacloud.io',
      login: 'https://login-rlsqa.congacloud.io',
      base: 'https://rls-qa.congacloud.io',
      coreapps: 'https://coreapps-rlsqa.congacloud.io',
      xauthor: 'https://xauthor-qa.congacloud.io',
      cci: 'https://cci-rlsqa.congacloud.io'
    },
    cloudflare: {
      zoneId: '7f069124a98de8f87c7bac8f85cf7c65',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://stg-telemetry.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Development',
      envName: 'DEVELOPMENT',
      url: 'https://rancher-engg.congacloud.io',
      fetchFromRancher: true
    },
    teams: {
      releaseNotificationWebhook: ''
    },
    doppler: {
      projectName: 'qa'
    },
    redis: {
      primary: {
        code: 'data'
      },
      secondary: null
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    aws: {
      primary: {
        region: 'us-west-2',
        regionCode: 'usw2',
        assumeRole: 'arn:aws:iam::568197319652:role/CrossAccountRole_OpsPortal'
      },
      secondary: null,
      accountId: '568197319652'
    },
    tidb: [],
    primaryCountryCode: 'US',
    secondCountryCode: '',
    launchDarkly: {
      opsPortalClientId: '66e6db4853dd8e0f68e8dc91'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'Staging US',
    externalDisplayName: 'Staging NA',
    envName: 'Staging',
    region: 'US',
    pageLink: 'staging-us',
    hasDrSupport: false,
    resourceCode: 'rls05',
    domain: 'congacloud.io',
    githubBranchName: 'stg-rls04',
    cloudopsManagedEnv: false,
    urls: {
      cm: 'https://rls-stg-cm.congacloud.io',
      login: 'https://login-rlsstg.congacloud.io',
      base: 'https://rls-stg.congacloud.io',
      coreapps: 'https://coreapps-rlsstg.congacloud.io',
      xauthor: 'https://xauthor-stg.congacloud.io',
      cci: 'https://cci-rlsstg.congacloud.io'
    },
    cloudflare: {
      zoneId: '7f069124a98de8f87c7bac8f85cf7c65',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://stg-telemetry.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Development',
      envName: 'DEVELOPMENT',
      url: 'https://rancher-engg.congacloud.io',
      fetchFromRancher: true
    },
    teams: {
      releaseNotificationWebhook: ''
    },
    doppler: {
      projectName: 'stg'
    },
    redis: {
      primary: {
        code: 'data'
      },
      secondary: null
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    aws: {
      primary: {
        region: 'us-west-2',
        regionCode: 'usw2',
        assumeRole: 'arn:aws:iam::944473829790:role/CrossAccountRole_OpsPortal'
      },
      secondary: null,
      accountId: '944473829790'
    },
    tidb: [],
    primaryCountryCode: 'US',
    secondCountryCode: '',
    launchDarkly: {
      opsPortalClientId: '66e78e4953dd8e0f68e935a9'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'Preview US',
    externalDisplayName: 'Preview NA',
    envName: 'Preview',
    region: 'US',
    pageLink: 'preview-us',
    hasDrSupport: false,
    resourceCode: 'rls09',
    domain: 'congacloud.com',
    githubBranchName: 'preview-rls09',
    cloudopsManagedEnv: true,
    urls: {
      cm: 'https://preview-rls09-cm.congacloud.com',
      login: 'https://login-rlspreview.congacloud.com',
      base: 'https://preview-rls09.congacloud.com',
      coreapps: 'https://coreapps-rlspreview.congacloud.com',
      xauthor: 'https://xauthor-preview-rls09.congacloud.com',
      cci: 'https://cci-rlspreview.congacloud.com'
    },
    cloudflare: {
      zoneId: '976eda921c3c863659186e10781971bd',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://grafana.prod-helium.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Production',
      envName: 'PRODUCTION',
      url: 'https://rancher.congacloud.com',
      fetchFromRancher: false
    },
    teams: {
      releaseNotificationWebhook:
        'https://prod-118.westus.logic.azure.com:443/workflows/2810e94240cb497983bbbc4dace6b647/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=DHZGCNnqbtMGLYDilWAihmS2u-8FkiAeXijHJm8rd50'
    },
    doppler: {
      projectName: 'preview'
    },
    redis: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    aws: {
      primary: {
        region: 'us-west-2',
        regionCode: 'usw2',
        assumeRole:
          'arn:aws:iam::722003666080:role/AWSCrossAccountAssumeRoleV2Preview'
      },
      secondary: null,
      accountId: '722003666080'
    },
    tidb: [
      {
        siteName: '',
        dashboardUrl:
          'http://a1b96f62847a14950b0edfdedb28ee9f-9a79fe1453bae3f3.elb.us-west-2.amazonaws.com:12333/dashboard'
      },
      {
        siteName: 'GEHC',
        dashboardUrl:
          'http://a1b96f62847a14950b0edfdedb28ee9f-9a79fe1453bae3f3.elb.us-west-2.amazonaws.com:12333/dashboard'
      }
    ],
    primaryCountryCode: 'US',
    secondCountryCode: '',
    launchDarkly: {
      opsPortalClientId: '66ab580581c7780f7a83b1cd'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'Preview EU',
    externalDisplayName: 'Preview EU',
    envName: 'Preview',
    region: 'EU',
    pageLink: 'preview-eu',
    hasDrSupport: false,
    resourceCode: 'rls12',
    domain: 'congacloud.eu',
    githubBranchName: 'previeweu-rls12',
    cloudopsManagedEnv: true,
    urls: {
      cm: 'https://preview-cm.congacloud.eu',
      login: 'https://login-preview.congacloud.eu',
      base: 'https://rls-preview.congacloud.eu',
      coreapps: 'https://coreapps-preview.congacloud.eu',
      xauthor: 'https://xauthor-preview.congacloud.eu',
      cci: 'https://cci-preview.congacloud.eu'
    },
    cloudflare: {
      zoneId: 'b79e2d086c63b066255f58663c6ae1e5',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://grafana.prod-helium.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Production',
      envName: 'PRODUCTION',
      url: 'https://rancher.congacloud.com',
      fetchFromRancher: false
    },
    teams: {
      releaseNotificationWebhook:
        'https://prod-119.westus.logic.azure.com:443/workflows/da5013d24609426db9f8f7b3160d6d26/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=txpOEhxWjnwsUN0E3sSX-kpWJ0Wi1LUBa2lcZtdCdg0'
    },
    doppler: {
      projectName: 'previeweu'
    },
    redis: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    aws: {
      primary: {
        region: 'eu-central-1',
        regionCode: 'euc1',
        assumeRole:
          'arn:aws:iam::722003666080:role/AWSCrossAccountAssumeRoleV2PreviewEu'
      },
      secondary: null,
      accountId: '722003666080'
    },
    tidb: [
      {
        siteName: '',
        dashboardUrl:
          'http://ae3b58bdbc3d9442ca7690d4e2dd8e02-cc885787b01310f7.elb.eu-central-1.amazonaws.com:12333/dashboard'
      }
    ],
    primaryCountryCode: 'EU',
    secondCountryCode: '',
    launchDarkly: {
      opsPortalClientId: '66abc84c05ec970ff9f09cd7'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'Preview AU',
    externalDisplayName: 'Preview AU',
    envName: 'Preview',
    region: 'AU',
    pageLink: 'preview-au',
    hasDrSupport: false,
    resourceCode: 'rls13',
    domain: 'congacloud.au',
    githubBranchName: 'previewau-rls13',
    cloudopsManagedEnv: true,
    urls: {
      cm: 'https://preview-cm.congacloud.au',
      login: 'https://login-preview.congacloud.au',
      base: 'https://rls-preview.congacloud.au',
      coreapps: 'https://coreapps-preview.congacloud.au',
      xauthor: 'https://xauthor-preview.congacloud.au',
      cci: 'https://cci-preview.congacloud.au'
    },
    cloudflare: {
      zoneId: '70091b30fe773da52c9264edc41818af',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://grafana.prod-helium.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Production',
      envName: 'PRODUCTION',
      url: 'https://rancher.congacloud.com',
      fetchFromRancher: false
    },
    teams: {
      releaseNotificationWebhook:
        'https://prod-127.westus.logic.azure.com:443/workflows/33a55c4cb09948759768f30ef2fb2e34/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=GOFamqVTVNDoELfLwYSG_MCB9ge2q5SVRk9ELwxqeZM'
    },
    doppler: {
      projectName: 'previewau'
    },
    redis: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: null
    },
    aws: {
      primary: {
        region: 'ap-southeast-2',
        regionCode: 'aps2',
        assumeRole:
          'arn:aws:iam::851725202596:role/AWSCrossAccountAssumeRoleV2previewau'
      },
      secondary: null,
      accountId: '851725202596'
    },
    tidb: [
      {
        siteName: '',
        dashboardUrl:
          'http://af4619e6474ec42258640dcc35dfc8da-f85f53cbe315bc73.elb.ap-southeast-2.amazonaws.com:12333/dashboard'
      }
    ],
    primaryCountryCode: 'AU',
    secondCountryCode: '',
    launchDarkly: {
      opsPortalClientId: '66abc85a5f65b90f4fce1391'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'Production US',
    externalDisplayName: 'Production NA',
    envName: 'Production',
    region: 'US',
    pageLink: 'production-us',
    hasDrSupport: true,
    resourceCode: 'rls10',
    domain: 'congacloud.com',
    githubBranchName: 'prod-rls10',
    cloudopsManagedEnv: true,
    urls: {
      cm: 'https://prod-rls10-cm.congacloud.com',
      login: 'https://login-rls.congacloud.com',
      base: 'https://prod-rls10.congacloud.com',
      coreapps: 'https://coreapps-rlsprod.congacloud.com',
      xauthor: 'https://xauthor-prod-rls10.congacloud.com',
      cci: 'https://cci-rlsprod.congacloud.com'
    },
    cloudflare: {
      zoneId: '976eda921c3c863659186e10781971bd',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://grafana.prod-helium.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Production',
      envName: 'PRODUCTION',
      url: 'https://rancher.congacloud.com',
      fetchFromRancher: false
    },
    teams: {
      releaseNotificationWebhook:
        'https://prod-118.westus.logic.azure.com:443/workflows/2810e94240cb497983bbbc4dace6b647/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=DHZGCNnqbtMGLYDilWAihmS2u-8FkiAeXijHJm8rd50'
    },
    doppler: {
      projectName: 'prod'
    },
    redis: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    aws: {
      primary: {
        region: 'us-west-2',
        regionCode: 'usw2',
        assumeRole:
          'arn:aws:iam::722003666080:role/AWSCrossAccountAssumeRoleV2prod'
      },
      secondary: {
        region: 'us-east-1',
        regionCode: 'use1',
        assumeRole:
          'arn:aws:iam::722003666080:role/AWSCrossAccountAssumeRoleV2prod'
      },
      accountId: '722003666080'
    },
    tidb: [
      {
        siteName: '',
        dashboardUrl:
          'http://aa3e95f68ec474f8db42bc647068af3e-7d9825bfa18a8791.elb.us-west-2.amazonaws.com:12333/dashboard'
      },
      {
        siteName: 'DR',
        dashboardUrl:
          'http://a5e6c751a7de049319502012ad13e8ed-d5567a837642d858.elb.us-east-1.amazonaws.com:12333/dashboard'
      }
    ],
    primaryCountryCode: 'US',
    secondCountryCode: 'US',
    launchDarkly: {
      opsPortalClientId: '66abc86a6f45f1104b1b2fae'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'Production EU',
    externalDisplayName: 'Production EU',
    envName: 'Production',
    region: 'EU',
    hasDrSupport: true,
    pageLink: 'production-eu',
    resourceCode: 'rls11',
    domain: 'congacloud.eu',
    githubBranchName: 'prodeu-rls11',
    cloudopsManagedEnv: true,
    urls: {
      cm: 'https://rls-cm.congacloud.eu',
      login: 'https://login.congacloud.eu',
      base: 'https://rls.congacloud.eu',
      coreapps: 'https://coreapps.congacloud.eu',
      xauthor: 'https://xauthor.congacloud.eu',
      cci: 'https://cci.congacloud.eu'
    },
    cloudflare: {
      zoneId: 'b79e2d086c63b066255f58663c6ae1e5',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://grafana.prod-helium.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Production',
      envName: 'PRODUCTION',
      url: 'https://rancher.congacloud.com',
      fetchFromRancher: false
    },
    teams: {
      releaseNotificationWebhook:
        'https://prod-119.westus.logic.azure.com:443/workflows/da5013d24609426db9f8f7b3160d6d26/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=txpOEhxWjnwsUN0E3sSX-kpWJ0Wi1LUBa2lcZtdCdg0'
    },
    doppler: {
      projectName: 'prodeu'
    },
    redis: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    aws: {
      primary: {
        region: 'eu-central-1',
        regionCode: 'euc1',
        assumeRole:
          'arn:aws:iam::722003666080:role/AWSCrossAccountAssumeRoleV2prodeu'
      },
      secondary: {
        region: 'eu-west-1',
        regionCode: 'euw1',
        assumeRole:
          'arn:aws:iam::722003666080:role/AWSCrossAccountAssumeRoleV2prodeu'
      },
      accountId: '722003666080'
    },
    tidb: [
      {
        siteName: '',
        dashboardUrl:
          'http://a955a9a6b27a64764893272741cd5607-76c15da994efe8c4.elb.eu-central-1.amazonaws.com:12333/dashboard'
      },
      {
        siteName: 'DR',
        dashboardUrl:
          'http://a705db0d9e89e40ebb9f1fd5268fe210-b147acc7eb3bcf10.elb.eu-west-1.amazonaws.com:12333/dashboard'
      }
    ],
    primaryCountryCode: 'EU',
    secondCountryCode: 'EU',
    launchDarkly: {
      opsPortalClientId: '66abc877334c88105a8f44d4'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'Production AU',
    externalDisplayName: 'Production AU',
    envName: 'Production',
    region: 'AU',
    hasDrSupport: true,
    pageLink: 'production-au',
    resourceCode: 'rls14',
    domain: 'congacloud.au',
    githubBranchName: 'prodau-rls14',
    cloudopsManagedEnv: true,
    urls: {
      cm: 'https://rls-cm.congacloud.au',
      login: 'https://login.congacloud.au',
      base: 'https://rls.congacloud.au',
      coreapps: 'https://coreapps.congacloud.au',
      xauthor: 'https://xauthor.congacloud.au',
      cci: 'https://cci.congacloud.au'
    },
    cloudflare: {
      zoneId: '70091b30fe773da52c9264edc41818af',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://grafana.prod-helium.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Production',
      envName: 'PRODUCTION',
      url: 'https://rancher.congacloud.com',
      fetchFromRancher: false
    },
    teams: {
      releaseNotificationWebhook:
        'https://prod-127.westus.logic.azure.com:443/workflows/33a55c4cb09948759768f30ef2fb2e34/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=GOFamqVTVNDoELfLwYSG_MCB9ge2q5SVRk9ELwxqeZM'
    },
    doppler: {
      projectName: 'prodau'
    },
    redis: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    opensearch: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    postgres: {
      primary: {
        code: 'data01'
      },
      secondary: {
        code: 'data02'
      }
    },
    aws: {
      primary: {
        region: 'ap-southeast-2',
        regionCode: 'aps2',
        assumeRole:
          'arn:aws:iam::851725202596:role/AWSCrossAccountAssumeRoleV2prodau'
      },
      secondary: {
        region: 'ap-southeast-1',
        regionCode: 'aps1',
        assumeRole:
          'arn:aws:iam::851725202596:role/AWSCrossAccountAssumeRoleV2prodau'
      },
      accountId: '851725202596'
    },
    tidb: [
      {
        siteName: '',
        dashboardUrl:
          'http://ae9a23494731048f8a5367a47f5e039e-332b8df4b9d05f54.elb.ap-southeast-2.amazonaws.com:12333/dashboard'
      },
      {
        siteName: 'DR',
        dashboardUrl:
          'http://a3e6802d30c2e44d99c88283ac238b46-e1a50ef310f339e5.elb.ap-southeast-1.amazonaws.com:12333/dashboard'
      }
    ],
    primaryCountryCode: 'AU',
    secondCountryCode: 'AU',
    launchDarkly: {
      opsPortalClientId: '66abc8846f45f1104b1b2fc7'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  },
  {
    displayName: 'Preview clone EU',
    externalDisplayName: 'Previewclone EU',
    envName: 'Previewclone',
    region: 'EU',
    hasDrSupport: false,
    pageLink: 'previewclone-eu',
    resourceCode: 'rls12',
    domain: 'congacloud.eu',
    githubBranchName: 'previewcloneeu-rls12',
    cloudopsManagedEnv: true,
    urls: {
      cm: 'https://preview-cm-clone.congacloud.eu',
      login: 'https://login-preview-clone.congacloud.eu',
      base: 'https://rls-preview-clone.congacloud.eu',
      coreapps: 'https://coreapps-preview-clone.congacloud.eu',
      xauthor: 'https://xauthor-preview-clone.congacloud.eu',
      cci: 'https://cci-preview-clone.congacloud.eu'
    },
    cloudflare: {
      zoneId: 'b79e2d086c63b066255f58663c6ae1e5',
      accountId: '80dc7b6c0625ece515903ff81bf06c0a'
    },
    grafana: {
      instanceUrl: 'https://grafana.prod-helium.conga-panoptos.com'
    },
    rancher: {
      name: 'Rancher Production',
      envName: 'PRODUCTION',
      url: 'https://rancher.congacloud.com',
      fetchFromRancher: false
    },
    teams: {
      releaseNotificationWebhook: ''
    },
    doppler: {
      projectName: 'previeweu_dr'
    },
    redis: {
      primary: {
        code: 'data02'
      }
    },
    opensearch: {
      primary: {
        code: 'data02'
      }
    },
    postgres: {
      primary: {
        code: 'data02'
      }
    },
    aws: {
      primary: {
        region: 'eu-west-1',
        regionCode: 'euw1',
        assumeRole:
          'arn:aws:iam::722003666080:role/AWSCrossAccountAssumeRoleV2PreviewEu'
      },
      accountId: '851725202596'
    },
    tidb: [],
    primaryCountryCode: 'EU',
    launchDarkly: {
      opsPortalClientId: '6760cfdcd0c05f093a3e4639'
    },
    tidbMigration: {
      customerGroupMigrationLimit: 5
    }
  }
];

export default rlsEnvDetails;
