const searchType = [
  {
    value: 'searchRlsCustomers',
    text: 'Search RLS Customers',
    icon: (
      <img
        src="/static/images/logos/temple.svg"
        alt="all"
        style={{ width: 20, height: 20, marginRight: 8 }}
      />
    )
  }
];

export default searchType;
