import { createSlice } from '@reduxjs/toolkit';

import { searchType } from '../../data/search';
import { seachQuery, rlsEnvDetails } from '../../data/Rls';

const computeRlsEnvironmentSearchSettings = () => {
  const environmentDetails = [];

  // eslint-disable-next-line array-callback-return
  rlsEnvDetails.map((environment) => {
    environmentDetails.push({
      ...environment,
      loading: false,
      loadingMessage: '',
      matchingCustomers: [],
      errorMessage: '',
      progress: 0,
      progressColor: 'primary',
      accordionDisabled: true
    });
  });

  return environmentDetails;
};

const initialState = {
  searchBoxOpen: false,
  searchType: searchType[0],
  searchTerm: '',
  rlsSearchParameter: seachQuery[0],
  searchLoading: {
    state: false,
    message: 'Searching...'
  },
  searchMessage: {
    severity: 'info',
    variant: 'outlined',
    title: 'Enter Search Term',
    description:
      'Please enter a search term to look up customers across multiple RLS environments you have access to. The Ops Portal will process your search term sequentially.'
  },
  searchResultMetadata: {
    totalCustomersFound: 0,
    totalMatchingEnvironmentsFound: 0
  },
  rlsEnvironments: computeRlsEnvironmentSearchSettings(),
  rlsComponentGroupExpanded: {}
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    changeSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    updateSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    updateRlsSearchParameter: (state, action) => {
      state.rlsSearchParameter = action.payload;
    },
    updateSearchLoading: (state, action) => {
      state.searchLoading = action.payload;
    },
    updateRlsEnvironmentSearchLoading: (state, action) => {
      const {
        environmentName,
        loading,
        loadingMessage,
        progress,
        progressColor,
        accordionDisabled,
        matchingCustomers
      } = action.payload;
      const index = state.rlsEnvironments.findIndex(
        (env) => env.pageLink === environmentName
      );
      if (index >= 0) {
        state.rlsEnvironments[index].loading = loading;
        state.rlsEnvironments[index].loadingMessage = loadingMessage;
        state.rlsEnvironments[index].progress = progress;
        state.rlsEnvironments[index].progressColor = progressColor;
        state.rlsEnvironments[index].accordionDisabled = accordionDisabled;
        state.rlsEnvironments[index].matchingCustomers = matchingCustomers;
      } else {
        console.error(`No environment found with pageLink: ${environmentName}`);
      }
    },

    sortEnvironments: (state) => {
      // push environments having matchingCustomers to the top
      const environmentsWithCustomers = state.rlsEnvironments.filter(
        (env) => env.matchingCustomers.length > 0
      );

      const environmentsWithoutCustomers = state.rlsEnvironments.filter(
        (env) => env.matchingCustomers.length === 0
      );

      state.rlsEnvironments = environmentsWithCustomers.concat(
        environmentsWithoutCustomers
      );
    },
    changeSearchBoxOpen: (state, action) => {
      state.searchBoxOpen = action.payload;
    },
    changeRlsComponentGroupExpanded: (state, action) => {
      state.rlsComponentGroupExpanded = action.payload;
    },
    changeSearchMessage: (state, action) => {
      state.searchMessage = action.payload;
    },
    updateSearchResultMetadata: (state, action) => {
      state.searchResultMetadata = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  changeSearchType,
  updateSearchTerm,
  updateRlsSearchParameter,
  updateSearchLoading,
  updateRlsEnvironmentSearchLoading,
  sortEnvironments,
  changeSearchBoxOpen,
  changeRlsComponentGroupExpanded,
  changeSearchMessage,
  updateSearchResultMetadata
} = searchSlice.actions;

export default searchSlice.reducer;
