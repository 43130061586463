const seachQuery = [
  {
    value: 'CustomerId',
    text: 'Customer ID',
    boolean: false
  },
  {
    value: 'CustomerDisplayName',
    text: 'Customer Display Name',
    boolean: false
  },
  {
    value: 'OrganizationId',
    text: 'Organization ID',
    boolean: false
  },
  {
    value: 'OrganizationFriendlyId',
    text: 'Organization FID',
    boolean: false
  },
  {
    value: 'LicenseAccountName',
    text: 'Account Name',
    boolean: false
  },
  {
    value: 'LicenseAccountId',
    text: 'Account ID',
    boolean: false
  },
  {
    value: 'CustomerDisplayName',
    text: 'Customer Display Name',
    boolean: false
  },
  { value: 'CurrentInfraId', text: 'Current Infra ID', boolean: false },
  { value: 'SeedPackageVersion', text: 'Seed Package Version', boolean: false },
  {
    value: 'CurrentPackageVersion',
    text: 'Current Package Version',
    boolean: false
  },
  { value: 'StorageName', text: 'Storage Name', boolean: false },
  { value: 'DatabaseName', text: 'TIDB Database Name', boolean: false }
];

export default seachQuery;
