import axios from 'axios';
import fetchToken from './fetchToken';
import { store } from 'src/store/store';

import {
  handleCustomersList,
  handleLoading,
  handleShowingSearchResults,
  handleSearchTotal
} from 'src/store/reducers/rls';

let retryLimit = 3;

const getSeachedCustomer = async (
  envName,
  envDetails,
  query,
  type,
  stateChange = true
) => {
  envName = envName.replace('-', '_');
  const res = {
    success: false,
    data: null,
    message: null
  };

  try {
    if (stateChange) {
      store.dispatch(
        handleLoading({
          name: 'customers',
          type,
          isLoading: true,
          loadingMessage: `Fetching customer details matching ${query.key} equals to ${query.seachTerm}`
        })
      );
    }

    const tokenRes = await fetchToken(envName, 'cm_token');
    if (tokenRes.success) {
      let requestConfig = {
        method: 'GET',
        url: `${envDetails?.urls?.cm}/api/customermanagement/v1/odata/CustomerQuery?&$filter=contains(${query.key}, '${query.seachTerm}') and IsActiveCustomer eq ${query.isActiveCustomer}&$top=${query.limit}&$skip=${query.skip}&$count=true`,
        headers: {
          accept: '*/*',
          Authorization: `Bearer ${tokenRes.token}`
        },
        timeout: 5000
      };
      const response = await axios(requestConfig);

      if (response.status === 200) {
        if (stateChange) {
          store.dispatch(
            handleCustomersList({
              name: 'customers',
              type: type,
              value: response.data.value
            })
          );
          store.dispatch(
            handleSearchTotal({
              name: 'customers',
              type: type,
              value: response.data['@odata.count']
            })
          );
          store.dispatch(
            handleLoading({
              name: 'customers',
              type: type,
              isLoading: false,
              loadingMessage: `Fetched customer details matching ${query.key} equals to ${query.seachTerm}`
            })
          );
          store.dispatch(
            handleShowingSearchResults({
              name: 'customers',
              type: type,
              value: true
            })
          );
        }
        retryLimit = 3;
        res.success = true;
        res.data = response.data.value;
        res.message = `Fetched customer details matching ${query.key} equals to ${query.seachTerm}`;
      } else if (response.status === 401) {
        if (retryLimit > 0) {
          retryLimit--;
          await getSeachedCustomer(envName, envDetails, query, type);
        } else {
          res.success = false;
          res.data = null;
          res.message =
            'You are not authorized to access customers details. please relogin';
        }
      } else {
        if (stateChange) {
          store.dispatch(
            handleLoading({
              name: 'customers',
              type: type,
              isLoading: false,
              loadingMessage: `Error Fetching customer details matching ${query.key} equals to ${query.seachTerm}`
            })
          );
        }
        retryLimit = 3;
        res.success = false;
        res.data = null;
        res.message =
          'Unexpected error occured. please relogin or reach out to cloudops team';
      }
    }
  } catch (err) {
    if (stateChange) {
      store.dispatch(
        handleLoading({
          name: 'customers',
          type: type,
          isLoading: false,
          loadingMessage: `Error Fetching customer details matching ${query.key} equals to ${query.seachTerm}`
        })
      );
    }
    res.success = false;
    res.data = null;
    res.message = err.message;
  }
  return res;
};

export default getSeachedCustomer;
