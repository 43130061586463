import { forwardRef, useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  Dialog,
  Slide,
  Menu,
  MenuItem,
  FormControl,
  OutlinedInput,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Grid,
  Container,
  Avatar,
  CardActions,
  Alert,
  AlertTitle,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';

import Flag from 'react-flagkit';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { searchType } from 'src/data/search';
import { seachQuery } from 'src/data/Rls';
import Label from 'src/components/Label';
import { useNavigate } from 'react-router-dom';

import { store } from 'src/store/store';

import {
  changeSearchType,
  updateSearchTerm,
  updateRlsSearchParameter,
  updateRlsEnvironmentSearchLoading,
  sortEnvironments,
  changeSearchBoxOpen,
  changeRlsComponentGroupExpanded,
  updateSearchLoading,
  changeSearchMessage,
  updateSearchResultMetadata
} from 'src/store/reducers/search';
import hasAccess from 'src/utils/usefulFunc/hasAccess';
import fetchToken from 'src/content/applications/Rls/api/fetchToken';
import getSeachedCustomer from 'src/content/applications/Rls/api/getSeachedCustomer';

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CallMadeTwoToneIcon from '@mui/icons-material/CallMadeTwoTone';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HourglassBottomTwoToneIcon from '@mui/icons-material/HourglassBottomTwoTone';
import CodeTwoToneIcon from '@mui/icons-material/CodeTwoTone';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import AssuredWorkloadTwoToneIcon from '@mui/icons-material/AssuredWorkloadTwoTone';
import CloudQueueTwoToneIcon from '@mui/icons-material/CloudQueueTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
        width: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px);
        max-width: calc(100vw - 30%);
    }
`
);

const SearchHeadingBox = styled(Box)(
  ({ theme }) => `
   background: ${theme.colors.alpha.white[100]};
   `
);

const SearchBox = styled(Box)(
  ({ theme }) => `
   background: transparent;
   padding-top: ${theme.spacing(2.5)};
   padding-left: ${theme.spacing(2.5)};
   padding-right: ${theme.spacing(2.5)};
   `
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
        background-color: ${theme.colors.alpha.white[100]};
        padding-right: ${theme.spacing(0.7)};
    `
);

const StyledSearchHeading = styled(Typography)(
  ({ theme }) => `
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(14)};
      color: ${theme.colors.alpha.trueWhite[70]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
`
);

const StyledFormControl = styled(FormControl)(
  () => `
      margin-bottom: 1;
    `
);

const LoaderContainer = styled(Box)(() => ({
  height: 70,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40%',
    backgroundImage: `url('/static/images/icons/grass.png')`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'auto 100%',
    zIndex: 1
  },
  '& img': {
    zIndex: 2,
    position: 'absolute',
    bottom: '2px'
  }
}));

const RunningCharacter = styled('img')(({ isMovingRight }) => ({
  position: 'absolute',
  bottom: 1,
  height: '70px', // Adjust based on your GIF size
  transform: `scaleX(${isMovingRight ? 1 : -1})`,
  transition: 'transform 0.3s ease'
}));

const AccordionSummaryWrapper = styled(AccordionSummary)(
  ({ theme }) => `
        &.Mui-expanded {
          min-height: 48px;
        }

        .MuiAccordionSummary-content.Mui-expanded {
          margin: 12px 0;
        }

        .MuiSvgIcon-root {
          transition: ${theme.transitions.create(['color'])};
        }

        &.MuiButtonBase-root {

          // margin-bottom: ${theme.spacing(0.5)};

          // &:last-child {
          //   margin-bottom: 0;
          // }

          &.Mui-expanded,
          &:hover {
            background: ${theme.colors.alpha.black[10]};

            .MuiSvgIcon-root {
              color: ${theme.colors.primary.main};
            }
          }
        }
`
);

function HeaderSearch() {
  const searchState = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(changeSearchBoxOpen(true));
  };

  const handleClose = () => {
    dispatch(changeSearchBoxOpen(false));
  };

  return (
    <>
      <Tooltip arrow title="Search">
        <IconButton color="primary" onClick={handleClickOpen}>
          <SearchTwoToneIcon />
        </IconButton>
      </Tooltip>

      <DialogWrapper
        open={searchState.searchBoxOpen}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <SearchHeadingBox
          sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <StyledSearchHeading> 🔍 Ops Portal Search</StyledSearchHeading>
          <SearchTypeChange />
        </SearchHeadingBox>
        <Divider />
        <Divider />
        <SearchBox>
          {searchState.searchType.value === 'searchRlsCustomers' && (
            <SearchBoxForRLS />
          )}
        </SearchBox>
        {searchState.searchLoading.state && (
          <>
            <RunningLoader
              gifUrl="/static/images/icons/runner.gif"
              loadingText={searchState.searchLoading.message}
            />
          </>
        )}
        {!searchState.searchLoading.state && (
          <>
            <SearchMessage />
          </>
        )}
        {searchState.searchType.value === 'searchRlsCustomers' && (
          <RlsEnvironmentsSearch />
        )}
      </DialogWrapper>
    </>
  );
}

const SearchTypeChange = () => {
  const searchParamRef = useRef(null);
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.search);
  const [openSearchParam, setOpenSearchParam] = useState(false);

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        ref={searchParamRef}
        onClick={() => setOpenSearchParam(true)}
        endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
        startIcon={
          searchType.find((type) => type.value === searchState.searchType.value)
            ?.icon
        }
      >
        {searchState.searchType.text}
      </Button>
      <Menu
        disableScrollLock
        anchorEl={searchParamRef.current}
        onClose={() => setOpenSearchParam(false)}
        open={openSearchParam}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {searchType.map((_period) => (
          <MenuItem
            key={_period.value}
            onClick={() => {
              dispatch(changeSearchType(_period));
              setOpenSearchParam(false);
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            {_period.icon}
            {_period.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const SearchBoxForRLS = () => {
  const searchState = useSelector((state) => state.search);
  const { accessControl } = useSelector((state) => state.userCustomData);
  const dispatch = useDispatch();
  const searchParamRef = useRef(null);

  const [openSearchParam, setOpenSearchParam] = useState(false);
  const [onlyOneMatchingOrgFound, setOnlyOneMatchingOrgFound] = useState(null);

  const handleSearch = async () => {
    let totalCustomersFound = 0;
    let totalMatchingEnvironmentsFound = 0;
    dispatch(changeRlsComponentGroupExpanded({}));
    dispatch(
      updateSearchLoading({
        state: true,
        message: 'Searching...'
      })
    );
    dispatch(
      updateSearchResultMetadata({
        totalCustomersFound,
        totalMatchingEnvironmentsFound
      })
    );
    dispatch(
      changeSearchMessage({
        severity: 'info',
        variant: 'outlined',
        title: 'Enter Search Term',
        description:
          'Please enter a search term to look up customers across multiple RLS environments you have access to. The Ops Portal will process your search term sequentially.'
      })
    );
    // loop through all the rls environments
    // eslint-disable-next-line no-restricted-syntax
    for (let environment of searchState.rlsEnvironments) {
      if (
        !hasAccess(
          `${environment.pageLink}-${environment.resourceCode}`,
          accessControl,
          ['read', 'write', 'admin']
        )
      ) {
        dispatch(
          updateRlsEnvironmentSearchLoading({
            environmentName: environment.pageLink,
            loading: false,
            loadingMessage: `You Don't have access to this Environment ❌`,
            progress: 100,
            progressColor: 'secondary',
            accordionDisabled: true,
            matchingCustomers: []
          })
        );
        // eslint-disable-next-line no-continue
        continue;
      }
      dispatch(
        updateRlsEnvironmentSearchLoading({
          environmentName: environment.pageLink,
          loading: true,
          loadingMessage: `Fetching Access token`,
          progress: 25,
          progressColor: 'primary',
          accordionDisabled: false,
          matchingCustomers: []
        })
      );
      const envName = environment.pageLink.replace('-', '_');
      // eslint-disable-next-line no-await-in-loop
      const tokenRes = await fetchToken(envName, 'cm_token');
      if (tokenRes.success) {
        dispatch(
          updateRlsEnvironmentSearchLoading({
            environmentName: environment.pageLink,
            loading: true,
            loadingMessage: `✅ Fetched Access token`,
            progress: 50,
            progressColor: 'primary',
            accordionDisabled: false,
            matchingCustomers: []
          })
        );
        dispatch(
          updateRlsEnvironmentSearchLoading({
            environmentName: environment.pageLink,
            loading: true,
            loadingMessage: `Fetching Customer Details`,
            progress: 75,
            progressColor: 'primary',
            accordionDisabled: false,
            matchingCustomers: []
          })
        );
        // eslint-disable-next-line no-await-in-loop
        const matchingCustomers = await getSeachedCustomer(
          environment.pageLink,
          environment,
          {
            key: seachQuery.filter(
              (item) => item.text === searchState.rlsSearchParameter.text
            )[0].value,
            seachTerm: searchState.searchTerm,
            isActiveCustomer: true,
            limit: 100,
            skip: 0
          },
          'activeCustomers',
          false
        );
        if (matchingCustomers.success) {
          if (matchingCustomers.data?.length > 0) {
            if (
              matchingCustomers.data?.length > 1 &&
              onlyOneMatchingOrgFound === true
            ) {
              setOnlyOneMatchingOrgFound(false);
            } else if (matchingCustomers.data?.length === 1) {
              setOnlyOneMatchingOrgFound(true);
            } else {
              setOnlyOneMatchingOrgFound(false);
            }
            dispatch(
              updateRlsEnvironmentSearchLoading({
                environmentName: environment.pageLink,
                loading: false,
                loadingMessage: `🎉 Found ${
                  matchingCustomers.data?.length
                } matching ${
                  matchingCustomers.data?.length === 1 ? 'org' : 'orgs'
                } `,
                progress: 100,
                progressColor: 'success',
                accordionDisabled: false,
                matchingCustomers: matchingCustomers?.data
              })
            );
            totalCustomersFound += matchingCustomers.data?.length || 0;
            totalMatchingEnvironmentsFound++;
          } else {
            dispatch(
              updateRlsEnvironmentSearchLoading({
                environmentName: environment.pageLink,
                loading: false,
                loadingMessage: `🙁 No Matching orgs Found`,
                progress: 100,
                progressColor: 'error',
                accordionDisabled: true,
                matchingCustomers: []
              })
            );
          }
        } else {
          dispatch(
            updateRlsEnvironmentSearchLoading({
              environmentName: environment.pageLink,
              loading: false,
              loadingMessage: `Failed to Fetch Customer Details ❌`,
              progress: 0,
              progressColor: 'error',
              accordionDisabled: true,
              matchingCustomers: []
            })
          );
        }
      } else {
        dispatch(
          updateRlsEnvironmentSearchLoading({
            environmentName: environment.pageLink,
            loading: false,
            loadingMessage: `Failed to Fetch Access Token ❌`,
            progress: 0,
            progressColor: 'error',
            accordionDisabled: true
          })
        );
      }
    }
    dispatch(sortEnvironments());
    dispatch(
      updateSearchLoading({
        state: false,
        message: 'Completed Search'
      })
    );
    dispatch(
      updateSearchResultMetadata({
        totalCustomersFound,
        totalMatchingEnvironmentsFound
      })
    );
    if (totalMatchingEnvironmentsFound > 0) {
      dispatch(
        changeSearchMessage({
          title: `Found ${totalCustomersFound} customers across ${totalMatchingEnvironmentsFound} RLS environments`,
          description: `You can expand the dropdown for each environment to view the customers matching your search query. If you prefer to see search results limited to a single environment, simply click the 'Go to Environment' button.`,
          variant: 'outlined',
          severity: 'success'
        })
      );
    } else {
      dispatch(
        changeSearchMessage({
          title: 'No matching customers found',
          description:
            'Please try a different search term or check your access permissions for the RLS environments.',
          variant: 'outlined',
          severity: 'error'
        })
      );
    }

    // fetch access token

    // fetch customers based on search query
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            component="img"
            src="/static/images/icons/profile.gif"
            alt="Loading"
            sx={{
              width: '30px', // Set appropriate size for the GIF
              height: '30px' // Match the GIF size to your needs
            }}
          />
          <Typography variant="h6" sx={{ ml: 1 }}>
            Environment Wide RLS Customers Search
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {' '}
          <Typography
            variant="subtitle2"
            sx={{
              pr: 1,
              mt: 0.5
            }}
          >
            Search By:
          </Typography>
          <Button
            size="small"
            variant="outlined"
            ref={searchParamRef}
            onClick={() => setOpenSearchParam(true)}
            endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
          >
            {searchState.rlsSearchParameter.text}
          </Button>
          <Menu
            disableScrollLock
            anchorEl={searchParamRef.current}
            onClose={() => setOpenSearchParam(false)}
            open={openSearchParam}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {seachQuery.map((_period) => (
              <MenuItem
                key={_period.value}
                onClick={() => {
                  dispatch(updateRlsSearchParameter(_period));
                  setOpenSearchParam(false);
                }}
              >
                {_period.text}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>

      <StyledFormControl
        variant="outlined"
        fullWidth
        sx={{ flexGrow: 1 }} // Ensure it takes remaining space
      >
        <OutlinedInputWrapper
          value={searchState.searchTerm}
          type="search"
          onChange={(e) => {
            dispatch(updateSearchTerm(e.target.value));
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter' && searchState.searchTerm.length > 0) {
              handleSearch();
            }
          }}
          placeholder={`Enter ${searchState.rlsSearchParameter.text}... (search parameter can be a partial match)`}
          endAdornment={
            <InputAdornment position="end">
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  handleSearch();
                }}
                disabled={!(searchState.searchTerm.length > 0)}
              >
                Search
              </Button>
            </InputAdornment>
          }
          startAdornment={
            <InputAdornment position="start">
              <SearchTwoToneIcon />
            </InputAdornment>
          }
        />
      </StyledFormControl>
    </Box>
  );
};
const RunningLoader = ({ gifUrl, loadingText = 'Loading...' }) => {
  const [isMovingRight, setIsMovingRight] = useState(true);
  const [position, setPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerRef, setContainerRef] = useState(null);

  // Update container width when window resizes
  useEffect(() => {
    if (!containerRef) return;

    const updateWidth = () => {
      setContainerWidth(containerRef.offsetWidth);
    };

    // Initial width
    updateWidth();

    // Update width on resize
    window.addEventListener('resize', updateWidth);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', updateWidth);
  }, [containerRef]);

  useEffect(() => {
    const speed = 5;
    const characterWidth = 70;
    let animationFrameId;

    const animate = () => {
      setPosition((prevPosition) => {
        let newPosition = prevPosition;

        if (isMovingRight) {
          newPosition = prevPosition + speed;
          if (newPosition >= containerWidth - characterWidth) {
            setIsMovingRight(false);
            return containerWidth - characterWidth;
          }
        } else {
          newPosition = prevPosition - speed;
          if (newPosition <= 0) {
            setIsMovingRight(true);
            return 0;
          }
        }

        return newPosition;
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    if (containerWidth > 0) {
      animationFrameId = requestAnimationFrame(animate);
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isMovingRight, containerWidth]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      minHeight="120px"
      overflow="hidden"
      sx={{ pt: 1, pb: 1, pl: 3, pr: 3 }}
    >
      <LoaderContainer elevation={1} ref={setContainerRef}>
        <RunningCharacter
          src={gifUrl || '/api/placeholder/40/40'}
          alt="Running character"
          style={{ left: `${position}px` }}
          isMovingRight={isMovingRight}
        />
      </LoaderContainer>
      <Typography
        variant="body1"
        sx={{
          mt: 1,
          color: 'body'
        }}
      >
        {loadingText}
      </Typography>
    </Box>
  );
};

const SearchMessage = () => {
  const searchState = useSelector((state) => state.search);
  const theme = useTheme();
  return (
    <Alert
      sx={{ color: theme.colors.secondary.dark, m: 2 }}
      variant={searchState.searchMessage.variant}
      severity={searchState.searchMessage.severity}
    >
      <AlertTitle>{searchState.searchMessage.title}</AlertTitle>
      {searchState.searchMessage.description}
    </Alert>
  );
};
const handleEnviromentLocalizedSearch = async (environment, navigate) => {
  navigate(`/rls/${environment.pageLink}/customers`);
  store.dispatch(changeSearchBoxOpen(false));
};

const RlsEnvironmentsSearch = () => {
  const searchState = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const accordionRefs = useRef({});
  const navigate = useNavigate();

  return (
    <Box sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
      {searchState.rlsEnvironments.map((environment) => (
        <Card key={environment.pageLink} sx={{ mb: 3 }}>
          <Accordion
            ref={(el) => (accordionRefs.current[environment.pageLink] = el)}
            sx={{ width: '100%', background: 'inherit' }}
            expanded={
              !!searchState.rlsComponentGroupExpanded[environment.pageLink]
            }
            onChange={(event, isExpanded) => {
              dispatch(
                changeRlsComponentGroupExpanded({
                  ...searchState.rlsComponentGroupExpanded,
                  [environment.pageLink]: isExpanded
                })
              );
            }}
          >
            <AccordionSummaryWrapper
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              disabled={environment.accordionDisabled}
              sx={{
                background: 'inherit',
                borderRadius: '0',
                pt: 0.5,
                pb: 0.5,
                position: 'relative'
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0,
                  left: -2
                }}
              >
                <LinearProgress
                  color={environment.progressColor}
                  variant="determinate"
                  value={environment.progress || 0}
                  sx={{
                    height: 2,
                    borderRadius: 2
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row'
                    }}
                  >
                    <Box sx={{ mt: 0.5 }}>
                      <Flag country={environment.primaryCountryCode} />
                    </Box>
                    <Typography variant="h5" sx={{ ml: 2 }}>
                      {environment.displayName} - {environment.resourceCode}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: 2 }}>
                    <Typography variant="subtitle3">
                      {environment.loadingMessage}
                    </Typography>
                    {!environment.loading &&
                      !environment.accordionDisabled &&
                      environment.matchingCustomers.length > 0 && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          sx={{ ml: 2 }}
                          endIcon={<CallMadeTwoToneIcon color="secondary" />}
                          onClick={() =>
                            handleEnviromentLocalizedSearch(
                              environment,
                              navigate
                            )
                          }
                        >
                          Go to Environment
                        </Button>
                      )}
                  </Box>
                </Box>
              </Box>
            </AccordionSummaryWrapper>
            <AccordionDetails sx={{ p: 0 }}>
              <Container>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    {environment.matchingCustomers.map((customer) => (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        sm={12}
                        lg={4}
                        key={customer.CustomerId}
                      >
                        <CustomerCard
                          customer={customer}
                          environment={environment}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Container>
            </AccordionDetails>
          </Accordion>
        </Card>
      ))}
    </Box>
  );
};

const CustomerCard = ({ customer, environment }) => {
  const dispatch = useDispatch();
  return (
    <Card>
      <Box p={2}>
        <Box sx={{ mb: 2 }}>
          {customer.IsUpgradeInProgress && (
            <Label color="yellowDark" sx={{ mr: 1 }}>
              <HourglassBottomTwoToneIcon fontSize="small" />
              Upgrade in progress ...
            </Label>
          )}
          {customer.IsOnboardingInProgress && (
            <Label color="blue" sx={{ mr: 1 }}>
              <HourglassBottomTwoToneIcon fontSize="small" />
              Onboarding in progress ...
            </Label>
          )}
        </Box>

        <Grid container spacing={1}>
          <Grid item xm={6}>
            <Avatar
              alt={customer.CustomerId}
              src={`https://api.dicebear.com/6.x/shapes/svg?seed=${
                customer.CustomerId ? customer.CustomerId : 'undefined'
              }`}
              sx={{ width: 64, height: 64 }}
            />
          </Grid>
          <Grid item xm={6}>
            <Typography
              variant="h4"
              component={Link}
              to={`/rls/${environment.pageLink}/customers/${customer.CustomerId}`}
              onClick={() => {
                dispatch(changeSearchBoxOpen(false));
              }}
              fontWeight="bold"
              color="primary"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {customer?.CustomerDisplayName
                ? customer.CustomerDisplayName?.length > 30
                  ? `${customer.CustomerDisplayName.substring(0, 30)}...`
                  : customer.CustomerDisplayName
                : customer.CustomerId?.length > 30
                ? `${customer.CustomerId.substring(0, 30)}...`
                : customer.CustomerId}
              {}
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle3"
                sx={{ marginBottom: 1 }}
              >
                <CodeTwoToneIcon
                  sx={{
                    mr: 1
                  }}
                />
                {`Schema version -> ${customer.CurrentPackageVersion}`}
              </Typography>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle3"
                sx={{ marginBottom: 1 }}
              >
                <AccountBoxTwoToneIcon
                  sx={{
                    mr: 1
                  }}
                />
                {`Customer ID -> ${customer.CustomerId}`}
              </Typography>

              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle3"
                sx={{ marginBottom: 1 }}
              >
                <BadgeTwoToneIcon
                  sx={{
                    mr: 1
                  }}
                />
                {`OrgID -> ${customer.OrganizationId}`}
              </Typography>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle3"
                sx={{ marginBottom: 1 }}
              >
                <AssuredWorkloadTwoToneIcon
                  sx={{
                    mr: 1
                  }}
                />
                {`OrgFID -> ${customer.OrganizationFriendlyId}`}
              </Typography>

              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle2"
                sx={{ marginBottom: 1 }}
              >
                <CloudQueueTwoToneIcon
                  sx={{
                    mr: 1
                  }}
                />
                {`Current Infra ID -> ${customer.CurrentInfraId}`}
              </Typography>
            </Box>
            <Box>
              <Label
                color={customer?.IsTrial ? 'secondary' : 'yellow'}
                sx={{ mr: 1, mt: 1 }}
              >
                {customer?.IsTrial ? 'Trial Customer' : 'Paid Customer'}
              </Label>
              <Label
                color={customer?.IsCongaIDP ? 'info' : 'primary'}
                sx={{ mr: 1, mt: 1 }}
              >
                {customer?.IsCongaIDP ? 'Conga IDP Login' : 'Salesforce Login'}
              </Label>
              {customer?.CurrentRevenuePackageVersion && (
                <Label color={'secondary'} sx={{ mr: 1, mt: 1 }}>
                  Revenue Customer
                </Label>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <CardActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 2
        }}
      >
        <Tooltip
          title={`ADMIN USER :: / EMAIL ID: ${customer.CustomerAdminEmailId} /  USERNAME: ${customer.CustomerAdminLoginId} /`}
          arrow
          placement="bottom"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            <Avatar
              alt={
                customer.CustomerAdminFirstName
                  ? customer.CustomerAdminFirstName
                  : customer.CustomerAdminEmailId
                  ? customer.CustomerAdminEmailId
                  : customer.CustomerAdminLoginId
              }
              src={`https://api.dicebear.com/6.x/micah/svg?seed=${
                customer.CustomerAdminFirstName
                  ? customer.CustomerAdminFirstName
                  : customer.CustomerAdminEmailId
                  ? customer.CustomerAdminEmailId
                  : customer.CustomerAdminLoginId
              }`}
              sx={{ width: 32, height: 32, marginRight: 1 }}
            />
            {customer?.CustomerAdminFirstName && customer?.CustomerAdminLastName
              ? `${customer.CustomerAdminFirstName} ${customer.CustomerAdminLastName}`
              : customer.CustomerAdminEmailId
              ? customer.CustomerAdminEmailId
              : customer.CustomerAdminLoginId
              ? customer.CustomerAdminLoginId
              : 'Unknown admin user'}
          </Box>
        </Tooltip>
        {customer?.InitialAdminLoginURL && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            endIcon={<OpenInNewIcon />}
            href={customer?.InitialAdminLoginURL}
            target="_blank"
          >
            Login
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default HeaderSearch;
