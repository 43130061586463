import { fetchAuthSession } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';
import Cookies from 'js-cookie';

import { store } from 'src/store/store';

const fetchToken = async (envName, type) => {
  const res = {
    success: false,
    cookieName: null,
    message: null,
    fetchedFromApi: false,
    token: null
  };
  try {
    const cookie = Cookies.get(`${envName}_${type}`);
    if (cookie) {
      const tokenFromCookie = cookie.split(' ')[0];
      const tokenGeneratedTime = cookie.split(' ')[1];
      const currTime = new Date().getTime();

      const timeDiffInMin = (currTime - tokenGeneratedTime) / (1000 * 60);
      if (timeDiffInMin < 55) {
        res.success = true;
        res.cookieName = `${envName}_cm_${type}`;
        res.fetchedFromApi = false;
        res.message = 'customer management token is valid & already fetched';
        res.token = tokenFromCookie;
        return res;
      }
      Cookies.remove(`${envName}_${type}`);
    }

    const { user, rls } = store.getState();

    const iss =
      user.signInUserSession.tokens.accessToken.payload.iss.split('/');

    const authToken = (
      await fetchAuthSession()
    ).tokens?.accessToken?.toString();

    // const amzDate = format(new Date(), "yyyyMMdd'T'HHmmss'Z'");

    // const awsAccessKeyId = authToken; // Replace with your actual access key ID
    // const currentDate = new Date().toISOString().slice(0, 8).replace(/-/g, ''); // Get current date in YYYYMMDD format
    // const awsRegion = 'us-west-2'; // Replace with your actual AWS region
    // const serviceName = 'execute-api'; // Replace with the AWS service name
    // const awsSignatureVersion = 'aws4_request';

    // const credential = `${awsAccessKeyId}/${currentDate}/${awsRegion}/${serviceName}/${awsSignatureVersion}`;

    let tokenType = '';
    switch (type) {
      case 'cm_token':
        tokenType = 'CM_TOKEN';
        break;
      case 'gt_token':
        tokenType = 'GLOBAL_TOKEN';
        break;
      case 'at_token':
        tokenType = 'APP_TRUST_TOKEN';
        break;
      case 'sign_provisioning_token':
        tokenType = 'SIGN_PROVISIONING_TOKEN';
        break;
      default:
        throw new Error('Invalid token type');
    }

    const fetchTokenOperation = post({
      apiName: 'congaOpsPortalRestApi',
      path: '/token',
      options: {
        body: {
          envName,
          tokenType,
          tokenUrl:
            type === 'cm_token'
              ? null
              : `${rls.currentEnv.urls.login}/api/v1/auth/connect/token`,
          userPoolId: iss[iss.length - 1].trim(),
          region: 'us-west-2'
        },
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    });
    const { body } = await fetchTokenOperation.response;
    const response = await body.json();

    if (response.access_token) {
      Cookies.set(
        `${envName}_${type}`,
        `${response.access_token} ${response.tokenGeneratedTime}`,
        {
          secure: true
        }
      );
      res.success = true;
      res.fetchedFromApi = true;
      res.cookieName = `${envName}_${type}`;
      res.message = `Fetched ${tokenType} successfully`;
      res.token = response.access_token;
    } else {
      res.success = false;
      res.message = `Failed to fetch ${tokenType} token`;

      res.cookieName = null;
      res.token = null;
      res.fetchedFromApi = true;
    }

    return res;
  } catch (err) {
    res.success = false;
    res.message = err.message;
    res.cookieName = null;
    res.fetchedFromApi = false;
    res.token = null;
    return res;
  }
};

export default fetchToken;
