import { configureStore } from '@reduxjs/toolkit';

import userReducer from './reducers/user';
import onespanReducer from './reducers/onespan';
import notificationReducer from './reducers/notification';
import rlsReducer from './reducers/rls';
import userCustomData from './reducers/userCustomData';
import statusPage from './reducers/statusPage';
import searchReducer from './reducers/search';

export const store = configureStore({
  reducer: {
    user: userReducer,
    onespan: onespanReducer,
    notification: notificationReducer,
    rls: rlsReducer,
    userCustomData: userCustomData,
    statusPage: statusPage,
    search: searchReducer
  }
});
